
import clientRendering from './clientRendering';

export default function isTouchDevice() {
  if (!clientRendering) {
    return () => {};
  }
  
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
};
