import styled from 'styled-components';

import media from '../../common/styles/media';

const S = {};

S.Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 23px;
  /* margin-left: -23px; */
  margin-left: -8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;
  pointer-events: none;

  ${({ isHidden }) => isHidden && `
    visibility: hidden;  
  `};

  @media ${media.mobile} {
    display: none;
  }
`;

S.Name = styled.div`
  display: inline-block;
  padding: 0 4px;
  color: var(--colorText);
  background: var(--background);
  white-space: nowrap;
`;

S.Date = styled.div`
  display: inline-block;
  padding: 0 4px;
  white-space: nowrap;
  color: var(--colorCaption); 
  background: var(--background); 
`;

S.Tags = styled.div`
  display: flex;
  background: var(--background); 
`;

S.Tag = styled.div`
  padding: 0 4px;
  color: var(--colorCaption);
  background: var(--background);    
`;

export default S;
