export const colors = {
};

export const sizes = {

};

export const zIndex = {
  filePreviewHover: 10,
  tagInfo: 100,
  filePopup: 200,
}
