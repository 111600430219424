import React, { useState, useEffect, useRef } from 'react';
import NextLink from 'next/link';

import { ITag } from '../../interfaces/tag';
import { IFile } from '../../interfaces/file';

import useOutsideClick from '../../common/useOutsideClick';
import isTouchDevice from '../../common/isTouchDevice';

import Tag from '../Tag';

import localIcons from '../../common/icons';

import S from './styles';

interface IProps {
  customCursorController: any,
  isOpen: boolean,
  setIsOpen: Function,
  tags: Array<ITag>,
  currentTag: ITag,
}

const Menu = (props: IProps) => {
  const {
    customCursorController,
    isOpen,
    setIsOpen,
    tags,
    currentTag,
  } = props;
  
  const containerRef = useRef();

  const [isCurrentTagAndHeaderHover, setIsCurrentTagAndHeaderHover] = useState(false);
  const [showChildrenTagsOfParentId, setShowChildrenTagsOfParentId] = useState(null);

  useEffect(() => {
    if (currentTag) {
      setShowChildrenTagsOfParentId(currentTag.parent || currentTag.id);
    }
  }, [currentTag]);

  useOutsideClick(containerRef, () => {
    // setIsOpen(false);
  });
  
  return (
    <S.Container
      ref={containerRef} 
      isOpen={isOpen}
      isCurrentTagAndHeaderHover={isCurrentTagAndHeaderHover}
    >
      <S.Popup>
        <S.PopupContent>
          <S.Items>
            {tags.map((tag, i) => {
              if (tag.isHide) return

              const type = tag.blockType;
              let isSelected = false;
              let isHasChildrenTags = false;
              let isHoverTextStyleDefault = false;
              let href;

              if (type === 'tag') {
                href = '/' + tag.slug;
                
                // check for children
                const nextChild = tags[i + 1];

                if (nextChild) {
                  const nextChildParentId = nextChild.parent;

                  if (nextChildParentId && nextChildParentId === tag.id) {
                    isHasChildrenTags = true;
                  }
                }

                isSelected = currentTag && currentTag.id === tag.id;

                if (tag.isSystemTag) {
                  isSelected = currentTag && currentTag.slug === tag.slug

                  if (tag.isStartTag) {
                    href = '/';
                  }
                }

                isHoverTextStyleDefault = !!tag.parent || showChildrenTagsOfParentId === tag.id;  
              }

              return (
                <S.Item
                  key={i}
                  isHide={tag.parent && showChildrenTagsOfParentId != tag.parent}
                >
                  {type === 'spacer' && <S.ItemSpacer></S.ItemSpacer>}
                  
                  {type === 'link' && (
                    <Tag
                      customCursorController={customCursorController}
                      isLinkStyledAsTag
                      tag={tag}
                      isMenuTag
                      nameDangerouslySetInnerHTML={{
                        __html: tag.name
                      }}
                      isNoHover={!!isTouchDevice()}
                    />
                  )}

                  {type === 'tag' && (
                    <NextLink
                      href={href}
                    >
                      <Tag
                        customCursorController={customCursorController}
                        tag={tag}
                        isMenuTag
                        isHasChildrenTags={isHasChildrenTags}
                        isHoverTextStyleDefault={isHoverTextStyleDefault} 
                        isSelected={isSelected}
                        isCursorDefault={isSelected}
                        isNoHover={!!isTouchDevice()}
                      />
                    </NextLink>
                  )}
                </S.Item>
              );  
            })}
          </S.Items>
        </S.PopupContent>
      </S.Popup>

      <S.CurrentTagAndTooltip
        isCurrentTagAndHeaderHover={isCurrentTagAndHeaderHover}
        onClick={() => {
          setIsOpen(true);
        }}
      >
         <S.Tooltip
            onMouseEnter={() => {
              if (!isTouchDevice()) {
                customCursorController.disable().unhideTrueCursor();
              }
              
            }}
            onMouseLeave={() => {
              if (!isTouchDevice()) {
                customCursorController.enable()
                // .hideTrueCursor();
              }
            }}
          >

          <S.TooltipCloseBtn
            onClick={(e) => {
              e.stopPropagation();

              setIsOpen(false);
            }}
          >
            <S.TooltipCloseBtnIcon>{localIcons.triangle}</S.TooltipCloseBtnIcon>
            <S.TooltipCloseBtnText>Hide</S.TooltipCloseBtnText>  
          </S.TooltipCloseBtn>
        </S.Tooltip>

        <S.CurrentTag>
          <Tag
            customCursorController={customCursorController}
            tag={currentTag}
            isMenuTag
            isNoIconChangeHover
            isNoHover
            isSelected
            handleMouseEnter={() => {
              if (!isTouchDevice()) {
                setIsCurrentTagAndHeaderHover(true);
              }
            }}
            handleMouseLeave={() => {
              if (!isTouchDevice()) {
                setIsCurrentTagAndHeaderHover(false);  
              }
            }}
          />
        </S.CurrentTag>

        <S.Header
          isTouch={!!isTouchDevice()}
          onMouseEnter={() => {
            if (!isTouchDevice()) {
              setIsCurrentTagAndHeaderHover(true);

              customCursorController.disable().unhideTrueCursor(); 
            }
          }}
          onMouseLeave={() => {
            if (!isTouchDevice()) {
              setIsCurrentTagAndHeaderHover(false);

              customCursorController.enable()
              // (); 
            }
          }}
        >
          <S.HeaderIcon>{localIcons.triangle}</S.HeaderIcon>
          <S.HeaderText>
            <S.HeaderFirstName>Anton</S.HeaderFirstName>
            <S.HeaderLastName> Herasymenko</S.HeaderLastName>
          </S.HeaderText>
        </S.Header>
      </S.CurrentTagAndTooltip>
    </S.Container>
  )
};

export default Menu;
