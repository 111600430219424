import React, { useEffect, useState, useCallback, useRef } from 'react';
import Head from 'next/head';
import { createGlobalStyle } from 'styled-components';
const CustomCursor = require('custom-cursor.js').default

import { ISystem } from '../../../interfaces/system';
import { ITag } from '../../../interfaces/tag';
import { IFile } from '../../../interfaces/file';

import Menu from '../../Menu';
import FileInfo from '../../FileInfo';

import S from './styles';

interface IProps {
  customCursorController: any,
  setCustomCursorController: Function,
  isMenuOpen: boolean,
  setIsMenuOpen: Function,
  system: ISystem,
  currentTag: ITag,
  setCurrentTag: Function,
  tags: Array<ITag>,
  setFiles: Function,
  currentFile: IFile,
  children: React.ReactNode,
  setIsMobileViewport: Function,
  currentlyHoveredFile: IFile,
  isScrolling: boolean,
}

const Layout = (props: IProps) => {
  const {
    customCursorController,
    setCustomCursorController,
    isMenuOpen,
    setIsMenuOpen,
    system,
    tags,  
    currentTag,
    setCurrentTag,
    setFiles,
    currentFile,
    setIsMobileViewport,
    currentlyHoveredFile,
    isScrolling,
    children  
  } = props;

  const customCursorRef = useRef(null);

  const [isDarkTheme, setIsDarkTheme]= useState(false);


  useEffect(() => {
    if (window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setIsDarkTheme(true);
      }

      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {

        setIsDarkTheme(event.matches);
      });
    }
  }, []);
  
  const onResize = useCallback(() => {
    if (window.innerWidth <= 767) {
      setIsMobileViewport(true);
    } else {
      setIsMobileViewport(false);
    }
  }, [setIsMobileViewport]);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [onResize]);

  useEffect(() => {
    onResize();
  }, []);

  // Disable Custom cursor
  useEffect(() => {
    if (customCursorRef && customCursorRef.current) {
      const customCursor = new CustomCursor(customCursorRef.current, {
        hideTrueCursor: false
     });

     customCursor.setPosition(-23, -23).initialize();

     setCustomCursorController(customCursor);
    }
  }, [customCursorRef]);

  let GlobalStyle;
  let background;

  if (system) {
    background = isDarkTheme ? system.darkBackground : system.background;
    
    GlobalStyle = createGlobalStyle`
      :root {
        --color: ${system.color};
        --background: ${system.background};
        --colorCaption: ${system.colorCaption};
        --colorText: ${system.colorText};
        --tagsTextColor: ${system.tagsTextColor};
        --tagsHoverTextColor: ${system.tagsHoverTextColor};
        --tagsBgColor: ${system.tagsBgColor};
        --tagsHoverBgColor: ${system.tagsHoverBgColor};

        --backgroundLight: ${system.background};
        --colorTextLight: ${system.colorText};
        --colorCaptionLight: ${system.colorCaption};
      }

      @media (prefers-color-scheme: dark) {
        :root {
          --color: ${system.darkColor};
          --background: ${system.darkBackground};
          --colorCaption: ${system.darkColorCaption};
          --colorText: ${system.darkColorText};
          --tagsTextColor: ${system.darkTagsTextColor};
          --tagsHoverTextColor: ${system.darkTagsHoverTextColor};
          --tagsBgColor: ${system.darkTagsBgColor};
          --tagsHoverBgColor: ${system.darkTagsHoverBgColor};
        }
      }

      body {
        background: var(--background);
        color: var(--colorText);
      }
    `;
  }

  return (
    <S.Container>
      <Head>
        {background &&
          <meta name="theme-color" content={background} />
        }
      </Head>

      {GlobalStyle && <GlobalStyle />}
      
      <S.CustomCursor
        ref={customCursorRef}
      >
        {(currentlyHoveredFile && !isScrolling) && <FileInfo
          file={currentlyHoveredFile}
        />}
      </S.CustomCursor>

      {(tags && currentTag && !currentFile) && <Menu
        customCursorController={customCursorController}
        isOpen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
        tags={tags} 
        currentTag={currentTag}
      />} 

      <S.Content>
        {children}
      </S.Content>
    </S.Container>
  )
};

export default Layout;
