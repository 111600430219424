export default {
  tagBig: <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.0122 20.2947C16.0122 20.967 15.5391 21.4526 14.8542 21.4526C14.1819 21.4526 13.7087 20.967 13.7087 20.2947V16.0122H7.73145V20.2947C7.73145 20.967 7.2583 21.4526 6.58594 21.4526C5.91357 21.4526 5.44043 20.967 5.44043 20.2947V16.0122H1.15796C0.485595 16.0122 0 15.5391 0 14.8542C0 14.1819 0.485595 13.7087 1.15796 13.7087H5.44043V7.73144H1.15796C0.485596 7.73144 0 7.2583 0 6.58594C0 5.91357 0.485596 5.44043 1.15796 5.44043H5.44043V1.15796C5.44043 0.485596 5.91357 0 6.58594 0C7.2583 0 7.73145 0.485596 7.73145 1.15796V5.44043H13.7087V1.15796C13.7087 0.485596 14.1819 0 14.8542 0C15.5391 0 16.0122 0.485596 16.0122 1.15796V5.44043H20.2947C20.967 5.44043 21.4526 5.91357 21.4526 6.58594C21.4526 7.2583 20.967 7.73145 20.2947 7.73145H16.0122V13.7087H20.2947C20.967 13.7087 21.4526 14.1819 21.4526 14.8542C21.4526 15.5391 20.967 16.0122 20.2947 16.0122H16.0122V20.2947ZM13.7087 13.7087V10.6912V7.73144H10.7615H7.73145V10.6912V13.7087H10.7615H13.7087Z" />
  </svg>,
  tagSmall: <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.6748 13.5298C10.6748 13.978 10.3594 14.3018 9.90283 14.3018C9.45459 14.3018 9.13916 13.978 9.13916 13.5298V10.6748L5.1543 10.6748V13.5298C5.1543 13.978 4.83887 14.3018 4.39062 14.3018C3.94238 14.3018 3.62695 13.978 3.62695 13.5298V10.6748H0.771972C0.32373 10.6748 0 10.3594 0 9.90283C0 9.45459 0.32373 9.13916 0.771972 9.13916H3.62695V5.1543H0.771973C0.32373 5.1543 0 4.83887 0 4.39062C0 3.94238 0.32373 3.62695 0.771973 3.62695H3.62695V0.771973C3.62695 0.32373 3.94238 0 4.39062 0C4.83887 0 5.1543 0.32373 5.1543 0.771973V3.62695H9.13916V0.771973C9.13916 0.32373 9.45459 0 9.90283 0C10.3594 0 10.6748 0.32373 10.6748 0.771973V3.62695H13.5298C13.978 3.62695 14.3018 3.94238 14.3018 4.39062C14.3018 4.83887 13.978 5.1543 13.5298 5.1543H10.6748V9.13916H13.5298C13.978 9.13916 14.3018 9.45459 14.3018 9.90283C14.3018 10.3594 13.978 10.6748 13.5298 10.6748H10.6748V13.5298ZM9.13916 9.13916V7.12744V5.1543H7.17432H5.1543V7.12744V9.13916H7.17432H9.13916Z" />
  </svg>,
  triangle: <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
    <rect width="33" height="33" fill="transparent"/>
    <path d="M18.2433 9.77711C17.6883 8.57465 17.4108 7.97342 17.0277 7.78584C16.6948 7.62281 16.3052 7.62281 15.9723 7.78584C15.5892 7.97342 15.3117 8.57465 14.7567 9.77711L8.7575 22.7754C8.33925 23.6816 8.13012 24.1347 8.1925 24.5013C8.247 24.8216 8.42902 25.1061 8.69697 25.2898C9.00366 25.5 9.5027 25.5 10.5008 25.5L22.4992 25.5C23.4973 25.5 23.9963 25.5 24.303 25.2898C24.571 25.1061 24.753 24.8216 24.8075 24.5013C24.8699 24.1347 24.6608 23.6816 24.2425 22.7754L18.2433 9.77711Z" />
  </svg>,
  email: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
    <path d="M15.4018 19.12L15.4025 19.1196C15.8172 18.9031 16.05 18.6255 16.05 18.2695C16.05 17.8682 15.7345 17.5247 15.3747 17.5247C15.1527 17.5247 14.878 17.6031 14.6081 17.7296L14.6071 17.7301C13.5547 18.248 12.1241 18.5333 10.6204 18.5333C8.20038 18.5333 6.22761 17.7595 4.85995 16.3844C3.49226 15.0094 2.724 13.0272 2.724 10.5996C2.724 8.08993 3.465 6.04512 4.7913 4.62825C6.11717 3.21183 8.03354 2.41693 10.3967 2.41693C12.7197 2.41693 14.5305 3.11642 15.761 4.426C16.992 5.73614 17.6499 7.66507 17.6499 10.1389C17.6499 11.7132 17.5449 12.8385 17.317 13.5685C17.2032 13.9332 17.0603 14.1941 16.889 14.3633C16.7195 14.5308 16.5198 14.6111 16.2836 14.6111C15.9106 14.6111 15.6205 14.4578 15.4222 14.2003C15.2227 13.9411 15.1131 13.5723 15.1131 13.1396V5.96777C15.1131 5.37367 14.6661 4.93658 14.0743 4.93658C13.4696 4.93658 13.0215 5.36016 13.0215 5.96777V6.39643C12.2658 5.42471 11.1293 4.87433 9.73949 4.87433C6.94328 4.87433 4.91122 7.27597 4.91122 10.4875C4.91122 13.6989 6.94312 16.1132 9.73949 16.1132C11.3466 16.1132 12.5179 15.4471 13.324 14.2211C13.5087 14.8765 13.8308 15.3856 14.2785 15.7363C14.7561 16.1103 15.3712 16.3 16.1018 16.3C17.1766 16.3 17.9924 15.7455 18.5358 14.6908C19.0771 13.6402 19.35 12.0912 19.35 10.0891C19.35 7.14611 18.5359 4.81976 17.005 3.22894C15.4739 1.63779 13.2333 0.790342 10.3967 0.790342C7.53026 0.790342 5.16464 1.75407 3.51571 3.46548C1.86701 5.17666 0.94 7.6299 0.94 10.5996C0.94 13.5258 1.85659 15.9295 3.49863 17.6019C5.14079 19.2743 7.50288 20.2097 10.3827 20.2097C12.4042 20.2097 13.9379 19.8594 15.4018 19.12ZM6.9609 10.4875C6.9609 8.3568 8.31343 6.77484 10.0891 6.77484C11.8639 6.77484 13.2032 8.40568 13.2032 10.4875C13.2032 12.5569 11.85 14.2003 10.0891 14.2003C8.31343 14.2003 6.9609 12.6183 6.9609 10.4875Z" strokeWidth="0.12"/>
  </svg>
}