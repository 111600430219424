import styled from 'styled-components';


const S = {};

S.Container = styled.div`
`;

S.Content = styled.div`
`;

S.CustomCursor = styled.div`
  display: none;
  position: fixed;
  z-index: 1000;
  top: -23px;
  left: -23px;
  width: 46px;
  height: 46px;
  pointer-events: none;
  user-select: none;
  border-radius: 100%;
  /* background: var(--color); */

  &.cursor--initialized {
    display: block;
  }
  
  &.cursor--off-screen {
    display: none;
  }

  &.cursor--disabled {
    display: none;
   }
`;

export default S;
