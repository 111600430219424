import React from 'react';

import { ITag } from '../../interfaces/tag';

import icons from '../../common/icons';

import S from './styles';

interface IProps {
  customCursorController?: any,
  tag: ITag,
  isLinkStyledAsTag?: boolean,
  isMenuTag?: boolean,
  isHideIcon?: boolean,
  isHasChildrenTags?: boolean,
  isHoverTextStyleDefault?: boolean,
  isNoIconChangeHover?: boolean,
  isSelected?: boolean,
  isGrayText?: boolean,
  isNoHover?: boolean,
  isNoDefaultBg?: boolean,
  isFilePage?: boolean,
  isCursorDefault?: boolean,
  nameDangerouslySetInnerHTML?: any,
  handleMouseEnter?: Function,
  handleMouseLeave?: Function,
}

const Tag = (props: IProps) => {
  const {
    customCursorController,
    tag,
    isLinkStyledAsTag,
    isMenuTag,
    isHideIcon,
    isHasChildrenTags,
    isHoverTextStyleDefault,
    isNoIconChangeHover,
    isSelected,
    isGrayText,
    isNoHover,
    isNoDefaultBg,
    isFilePage,
    isCursorDefault,
    nameDangerouslySetInnerHTML,
    handleMouseEnter,
    handleMouseLeave
  } = props;

  let _isHideIcon = isHideIcon;
  let isEmailLinkContent = false;

  if (isLinkStyledAsTag) {
    // test if link is email link
    const isEmailLink = tag?.name.indexOf('mailto') > -1;

    if (isEmailLink) {
      isEmailLinkContent = true;
    } else {
      _isHideIcon = true;
    }
  }

  return (
    <S.Container 
      isMenuTag={isMenuTag}
      isHideIcon={_isHideIcon}
      isLinkStyledAsTag={isLinkStyledAsTag}
      isEmailLinkContent={isEmailLinkContent}
      isSelected={isSelected}
      isGrayText={isGrayText}
      isHoverTextStyleDefault={isHoverTextStyleDefault}
      isNoIconChangeHover={isNoIconChangeHover}
      isNoHover={isNoHover}
      isNoDefaultBg={isNoDefaultBg}
      isFilePage={isFilePage}
      isCursorDefault={isCursorDefault}
      onMouseEnter={() => {
        if (customCursorController) {
          customCursorController.disable().unhideTrueCursor();
        }

        if (handleMouseEnter) handleMouseEnter();
      }}
      onMouseLeave={() => {
        if (customCursorController) {
          customCursorController.enable()
          // .hideTrueCursor();  
        }

        if (handleMouseLeave) handleMouseLeave();
      }}
    >
      <S.Main>
        <S.Content>
          <S.Icon>
            <S.IconBig>{icons.tagBig}</S.IconBig>
            <S.IconSmall>{icons.tagSmall}</S.IconSmall>
          </S.Icon>

          {nameDangerouslySetInnerHTML ?
            <S.Name
              dangerouslySetInnerHTML={nameDangerouslySetInnerHTML} 
            />
            :
            <S.Name>{tag.name}{isHasChildrenTags ? '/' : ''}</S.Name>
          }
        </S.Content>
      </S.Main>

      <S.Hover>
        <S.Content>
          {nameDangerouslySetInnerHTML ?
            <S.Name
              dangerouslySetInnerHTML={nameDangerouslySetInnerHTML} 
            />
            :
            <S.Name>{tag.name}</S.Name>
          }
          <S.Icon>
            <S.IconBig>{icons.tagBig}</S.IconBig>
            <S.IconSmall>{icons.tagSmall}</S.IconSmall>
            <S.IconEmail>{icons.email}</S.IconEmail>
          </S.Icon>
        </S.Content>  
      </S.Hover>
    </S.Container>
  )
};

export default Tag;
