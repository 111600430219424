import styled from 'styled-components';

import { zIndex } from '../../common/styles/const';
import media from '../../common/styles/media';

const S = {};

S.HeaderFirstName = styled.span``
S.HeaderLastName = styled.span`
  pointer-events: none;
`

S.HeaderIcon = styled.div`
  position: relative;
  margin-right: 1px;
  width: 33px;
  height: 33px;
  transform-origin: center center;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: var(--tagsHoverTextColor);
  }
`;

S.Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 33px;
  line-height: 33px;
  padding-right: 6px;
  font-size: 25px;
  text-transform: uppercase;
  background: var(--tagsHoverBgColor); 
  cursor: pointer;
`;

S.HeaderText = styled.div`
  color: var(--tagsHoverTextColor);
`;

S.CurrentTagAndTooltip = styled.div`
  position: relative;
  height: 66px;

  ${({ isTouch, isCurrentTagAndHeaderHover }) => (!isTouch && isCurrentTagAndHeaderHover) && `
    cursor: pointer;

    ${S.HeaderIcon} {
      transform: rotateZ(180deg);   
    }
  `};
`;

S.CurrentTag = styled.div`
  display: flex;
`;

S.Tooltip = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

S.TooltipCloseBtnIcon = styled.div`
  position: relative;
  margin-right: 1px;
  width: 33px;
  height: 33px;
  transform-origin: center center;
  transform: rotateZ(180deg);

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: var(--tagsTextColor);
  }
`;

S.TooltipCloseBtnText = styled.div`
  color: var(--tagsTextColor);
  text-transform: uppercase;
`;

S.TooltipCloseBtn = styled.button`
  display: none; 
  height: 33px;
  line-height: 33px;
  padding-right: 6px;
  font-size: 25px;
  background: var(--tagsBgColor);

  &:hover, &:active {
    cursor: pointer;

    ${S.TooltipCloseBtnText} {
      color: var(--tagsHoverTextColor);
    }

    ${S.TooltipCloseBtnIcon} {
      svg {
        fill: var(--tagsHoverTextColor);
      }
    }
  }
`;

S.Popup = styled.div`
  display: none;
  position: absolute;
  bottom: 33px;
  left: 0;
`;

S.PopupContent = styled.div`
`;

S.Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

S.Item = styled.div`
  ${({ isHide }) => isHide && `
    display: none;
  `};
  
  > a {
    cursor: pointer !important;
  }
`;

S.ItemHtmlWrapper = styled.div`
  
`;

S.ItemSpacer = styled.div`
  height: 33px;
`;

S.Container = styled.div`
  position: fixed;
  z-index: ${zIndex.tagInfo};
  bottom: 20px;
  left: 20px;
  user-select: none;

  @media ${media.mobile} {
    bottom: 10px;
    left: 10px;
  }

  ${({ isCurrentTagAndHeaderHover, isOpen }) => (isCurrentTagAndHeaderHover && !isOpen) && `
    ${S.TooltipMenu} {
      display: inline-block; 
    }
  `};

  ${({ isOpen }) => isOpen && `
    ${S.CurrentTag} {
      display: none;
    }

    ${S.CurrentTagAndTooltip} {
      height: 33px;
    }

    ${S.Tooltip} {
      top: 0;  
    }

    ${S.TooltipCloseBtn} {
      display: flex; 
    }

    ${S.Header} {
      display: none;
    }
    
    ${S.Popup} {
      display: block;
    }
  `};
`;

export default S;
