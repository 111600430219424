import styled from 'styled-components';
import media from '../../common/styles/media';

const S = {};

S.Name = styled.div`
  font-size: 18px;
  line-height: 23px;
  height: 23px;
  text-transform: uppercase;  
  white-space: nowrap;
  color: var(--tagsTextColor);

  @media ${media.mobile} {
    line-height: 25px;
    height: 25px;
  }
`;

S.Icon = styled.div`
  /* margin-right: 4px; */
  margin-right: 3px;

  svg {
    fill: var(--tagsTextColor);
  }
`;

S.IconBig = styled.div`
  display: none;
  position: relative;
  top: 6px;
  width: 22px;
  height: 22px;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
  }
`;

S.IconSmall = styled.div`
  position: relative;
  top: 4px;
  width: 15px;
  height: 15px;

  @media ${media.mobile} {
    top: 5px;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
  }
`;

S.IconEmail = styled.div`
  display: none;
  position: relative;
  top: 6px;
  width: 20px;
  height: 21px;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 21px;  
  }
`;

S.Main = styled.div`

`;

S.Hover = styled.div`
  display: none;

  ${S.Name} {
    margin-right: 4px;
  }

  ${S.Icon} {
    margin-right: 0 !important;

    svg {
      fill: var(--tagsHoverTextColor) !important;
    }
  }

  ${S.IconEmail} {
    svg {
      fill: var(--tagsHoverTextColor) !important;
      stroke: var(--tagsHoverTextColor) !important;
    }
  }
`;

S.Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 23px;
  padding-left: 4px;
  padding-right: 4px;

  &:hover, &:active {
    cursor: pointer;
  }

  ${({ isMenuTag, isNoHover, isSelected }) => (isMenuTag && !isNoHover && !isSelected) && `
    &:hover, &:active {
      background: var(--tagsHoverBgColor);

      ${S.Icon} {
        svg {
          fill: var(--tagsHoverTextColor);
        }
      }
    }
  `};

  @media ${media.mobile} {
    ${({ isMenuTag }) => !isMenuTag && `
      height: 25px;
      padding-left: 4px;
      padding-right: 4px; 
    `};
  } 

  ${({ isFilePage }) => isFilePage && `
    background: var(--background);

    ${S.Name} {
      color: var(--colorText);
    }

    ${S.Icon} {
      svg {
        fill: var(--colorText);
      }
    }

    &:hover, &:active {
      background: var(--color);
      cursor: pointer;

      ${S.Name} {
        color: var(--background);
      }

      ${S.Icon} {
        svg {
          fill: var(--background);
        }
      }
    }  
  `};

  ${({ isHideIcon }) => isHideIcon && `
    ${S.Icon} {
      display: none !important;
    }

    ${S.Name} {
      margin-right: 0 !important;
    }
  `};

  ${({ isMenuTag }) => isMenuTag && `
    height: 33px;
    padding-left: 6px;
    padding-right: 6px; 
    color: var(--tagsTextColor);
    background: var(--tagsBgColor);

    ${S.Name} {
      font-size: 25px;
      line-height: 33px;
      height: 33px;
    }

    ${S.Icon} {
      margin-right: 6px;
    }

    ${S.IconBig} {
      display: block;
    }

    ${S.IconSmall} {
      display: none;
    }

    ${S.Main} {
      ${S.Icon} {
        display: none;
      }
    }

    ${S.Hover} {
      ${S.Name} {
        margin-right: 6px;
      }
    }
  `};

  ${({ isEmailLinkContent }) => isEmailLinkContent && `
    ${S.IconBig} {
      display: none;
    }

    ${S.IconSmall} {
      display: none;
    }

    ${S.IconEmail} {
      display: block;
    }
  `};


  ${({ isLinkStyledAsTag, isNoHover }) => (isLinkStyledAsTag && !isNoHover) && `
    &:hover, &:active {
      background: var(--tagsHoverBgColor);
      cursor: pointer;

      ${S.Name} {
        color: var(--tagsHoverTextColor);
      }
      
      ${S.Icon} {
        svg {
          fill: var(--tagsHoverTextColor);
          stroke: var(--tagsHoverTextColor); 
        }
      }
    }
  `}; 

  ${({ isGrayText }) => isGrayText && `    
    ${S.Name} {
      color: var(--colorCaption);
    }

    ${S.Icon} {
      svg {
        fill: var(--colorCaption);
      }
    }
  `};

  ${({ isHoverTextStyleDefault }) => isHoverTextStyleDefault && `
    ${S.Name} {
      color: var(--tagsHoverTextColor);
    }    

    &:hover, &:active {
      ${S.Name} {
        color: var(--tagsHoverTextColor);  
      } 
    }
  `};

  ${({ isSelected }) => isSelected && `
    background: var(--tagsHoverBgColor);

    ${S.Name} {
      color: var(--tagsHoverTextColor);
    }

    ${S.Icon} {
      svg {
        fill: var(--tagsHoverTextColor);
      }
    }

    ${S.Main} {
      ${S.Icon} {
        display: block !important;
      }
    }
  `};


  ${({ isCursorDefault }) => isCursorDefault && `
    &:hover, &:active {
      cursor: default !important;
    }
  `};

  ${({ isSelected, isNoIconChangeHover }) => (!isSelected && !isNoIconChangeHover) && `
    &:hover, &:active {
      ${S.Hover} {
        display: flex;
      }

      ${S.Main} {
        display: none;
      }

      @media ${media.mobile} {
        ${S.Hover} {
          display: none;
        }

        ${S.Main} {
          display: block;
        }
      } 
    }
  `};
`;

S.Content = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export default S;
