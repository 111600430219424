import React, { useEffect } from 'react';

export default function useOutsideClick(ref: React.RefObject<HTMLElement>, callback: Function) {
  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref]);
};