export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function formatDate(dateString) {
  const date = new Date(dateString);

  const day = date.getDate().toString();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear().toString();

  let returnValue = `${month} ${day}, ${year}`;

  return returnValue;
}
