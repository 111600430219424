import Router from 'next/router';
import GA4React from 'ga-4-react';

let ga4react;

export async function initGoogleAnalytics(gTagId) {
  // console.log('initGoogleAnalytics', gTagId);

  if (!GA4React.isInitialized() && gTagId && process.browser) {
    ga4react = new GA4React(gTagId, { debug_mode: process.env.NODE_ENV !== 'production' });

    // console.log('new GA4React()');

    try {
      await ga4react.initialize();

      // console.log('ga4react.initialize()');

      logPageViews();
    } catch (error) {
      console.error(error);
    }
  }
}

function logPageView() {
  ga4react.pageview(window.location.pathname);
}

function logPageViews() {
  logPageView();

  Router.events.on('routeChangeComplete', () => {
    logPageView();
  });
}

export function logEvent(action, label, category) {
  ga4react.event(action, label, category);
}
