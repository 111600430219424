import React, { useState, useEffect } from 'react';
import { AppProps } from 'next/app';
import styled from 'styled-components';
import Head from 'next/head';
import axios from 'axios';

import { ISystem } from '../interfaces/system';
import { ITag } from '../interfaces/tag';
import { IFile, IClosestFiles } from '../interfaces/file';

import { initGoogleAnalytics } from '../common/ga';

import Layout from '../components/common/Layout';

import '../styles/fonts.css';
import '../styles/global.css';

axios.interceptors.request.use(
  function (config) {
    // Add timestamp to API request to disable cache
    config.params = { ...config.params, timestamp: Date.now() };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const Wrapper = styled.div`
  position: relative;
`;

function AntonHerasymenkoApp(props: AppProps) {
  const { 
    Component, 
    pageProps,
    ...rest
  } = props;

  const isProd = process.env.NODE_ENV === 'production';
  
  const [customCursorController, setCustomCursorController] = useState(null);
  const [system, setSystem] = useState<ISystem>(null);
  const [tags, setTags] = useState<Array<ITag>>(null);
  const [currentFile, setCurrentFile] = useState<IFile>(null);
  const [currentClosestFiles, setCurrentClosestFiles] = useState<IClosestFiles>(null);
  const [files, setFiles] = useState<Array<IFile>>(null);
  const [loadingFilesApiUrl, setLoadingFilesApiUrl] = useState<string>(null);
  const [nextFilePageApiUrl, setNextFilePageApiUrl] = useState(null);
  const [currentTag, setCurrentTag] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isMobileViewport, setIsMobileViewport] = useState(false);
  const [currentlyHoveredFile, setCurrentlyHoveredFile] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [customColumnsCount, setCustomColumnsCount] = useState(null);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setIsScrolling(true);  
    });
  }, []);

  function resetIsScrolling() {
    setIsScrolling(false);   
  }

  useEffect(() => {
    if (isProd && process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID) {
      initGoogleAnalytics(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID);
    }
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <title>Anton Herasymenko</title>
        <meta name="description" content="Life, Studio, Archive" />
      </Head>

      <Wrapper id="AppWrapper">
        <Layout 
          customCursorController={customCursorController}
          setCustomCursorController={setCustomCursorController}
          system={system}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          tags={tags}  
          currentTag={currentTag}
          setCurrentTag={setCurrentTag}
          setFiles={setFiles}
          currentFile={currentFile}
          setIsMobileViewport={setIsMobileViewport}
          currentlyHoveredFile={currentlyHoveredFile}
          isScrolling={isScrolling}
        >
          <Component 
            {...pageProps} 
            {...rest} 
            isMobileViewport={isMobileViewport}
            customCursorController={customCursorController}
            setCustomCursorController={setCustomCursorController}
            system={system}
            setSystem={setSystem} 
            tags={tags}
            setTags={setTags}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            currentTag={currentTag}
            setCurrentTag={setCurrentTag}
            currentFile={currentFile}
            setCurrentFile={setCurrentFile}
            files={files}
            setFiles={setFiles}
            nextFilePageApiUrl={nextFilePageApiUrl}
            setNextFilePageApiUrl={setNextFilePageApiUrl}
            loadingFilesApiUrl={loadingFilesApiUrl}
            setLoadingFilesApiUrl={setLoadingFilesApiUrl}
            currentClosestFiles={currentClosestFiles}
            setCurrentClosestFiles={setCurrentClosestFiles}
            setCurrentlyHoveredFile={setCurrentlyHoveredFile}
            resetIsScrolling={resetIsScrolling}
            customColumnsCount={customColumnsCount}
            setCustomColumnsCount={setCustomColumnsCount}
          />
        </Layout>
      </Wrapper>
    </>
  );
}

export default AntonHerasymenkoApp;
