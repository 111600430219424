import React from 'react';

import { IFile } from '../../interfaces/file';

import { formatDate } from '../../common/date';

import Tag from '../Tag';

import S from './styles';

interface IProps {
  file: IFile,
}

const FileInfo = (props: IProps) => {
  const {
    file,
  } = props;

  const tags = file?.tags.filter((t) => {
    return !t.isSystemTag;
  });

  const hasTags = tags && file.tags.length;

  return (
    <S.Container>
      <S.Name>{file.name}</S.Name>
      
      <S.Date>{formatDate(file.date)}</S.Date>
      
      {hasTags && <S.Tags>{tags.map((tag, i) => {
        return (
          <Tag
            key={i} 
            tag={tag}
            isGrayText
            isNoDefaultBg
        />
      );
      })}</S.Tags>}
    </S.Container>
  )
};

export default FileInfo;
